import {useState} from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/modal';

import {useGetMeQueryData} from '@eksab/hooks/useMeQuery';

import {Login} from '../components/Login';
import {Register} from '../components/Register';

type AuthRoute = 'login' | 'register';

interface AuthModalProps extends Omit<ModalProps, 'children' | 'onClose'> {
  onClose: (me: Me | undefined) => void;
  route?: AuthRoute;
}

export function AuthModal({route = 'register', ...props}: AuthModalProps) {
  const [currentForm, setCurrentForm] = useState<AuthRoute>(route);

  const getMe = useGetMeQueryData();

  function onClose() {
    const me = getMe();
    props.onClose(me);
  }

  return (
    <Modal {...props} onClose={onClose} variant="full">
      <ModalOverlay />
      <ModalContent py={[2.5, null, 10]}>
        <ModalHeader>
          <ModalCloseButton />
        </ModalHeader>

        <ModalBody textAlign="center">
          {currentForm === 'login' ? (
            <Login isModal onSignUpClick={() => setCurrentForm('register')} closeModal={onClose} />
          ) : (
            <Register isModal onLoginClick={() => setCurrentForm('login')} closeModal={onClose} />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
